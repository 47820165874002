import { Canvas } from '@react-three/fiber';
import { useEffect, useState } from 'react';

import { ProfPic } from './ProfPic';
import { Logo, } from './Logo.js';
import { Float } from './Float.js';
import { AsciiRenderer } from './AsciiRenderer.js';

import { Route, Switch } from "wouter";

import './App.css';
import { LiveShow } from './LiveShow.js';

function Home(props) {
  props.setColor('black');
  props.setAscii(true);
  return <>
    <Float>
      <ProfPic
        position={[3, -5.5, 0]} />
    </Float>
    <Float>
      <Logo position={[0, 10.5, 5]} />
    </Float>
  </>
}

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [color, setColor] = useState('black');
  const [ascii, setAscii] = useState(true);
  return (
    <div className='logo'>
      <Canvas camera={{ position: [0, 0, 30] }}>
        <Switch>
          <Route path="/live"><LiveShow setAscii={setAscii} setColor={setColor} /></Route>
          <Route path="" ><Home setAscii={setAscii} setColor={setColor} /></Route>
        </Switch>
        <color attach="background" args={[color]} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[10, 10, 10]} />
        {ascii ? <AsciiRenderer fgColor="blue" bgColor="white" /> : <></>}
      </Canvas>
    </div>
  )
}

export default App;
