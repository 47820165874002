import React, { useEffect, useState } from 'react';
import { Float } from './Float.js';
import { Link } from "wouter";
import { Html, Image } from '@react-three/drei';
import { getDatabase, ref, set, get, increment } from 'firebase/database';
import { load } from '@fingerprintjs/fingerprintjs';

import Subscribe from './Subscribe';
import {auth } from "./auth";

import liveBackground from './pics/live_background.png';
import liveGrid from './pics/live_grid.png';
import liveCello from './pics/live_cello.png';
import liveText from './pics/live_text.svg';
import liveApple from './assets/live.ics';
import './App.css';

auth();

const TrackedCalendarLinks = () => {
    const [fingerprint, setFingerprint] = useState(null);
    const db = getDatabase();
  
    useEffect(() => {
      // Initialize FingerprintJS to generate a browser fingerprint
      const initFingerprint = async () => {
        const fp = await load();
        const result = await fp.get();
        setFingerprint(result.visitorId);
      };
      
      initFingerprint();
    }, []);
  
    const trackClick = async (linkType) => {
      if (!fingerprint) return;
  
      try {
        // Reference to total clicks
        const totalClicksRef = ref(db, `clicks/${linkType}/total`);
        
        // Reference to unique clicks using fingerprint
        const uniqueClickRef = ref(db, `clicks/${linkType}/unique/${fingerprint}`);
        
        // Check if this browser has clicked before
        const uniqueSnapshot = await get(uniqueClickRef);
        
        // Always increment total clicks
        await set(totalClicksRef, increment(1));
        
        // Only count as unique if first time
        if (!uniqueSnapshot.exists()) {
          await set(uniqueClickRef, true);
          
          // Increment unique clicks counter
          const uniqueCountRef = ref(db, `clicks/${linkType}/uniqueCount`);
          await set(uniqueCountRef, increment(1));
        }
      } catch (error) {
        console.error('Error tracking click:', error);
      }
    };
  
    return (
        <div className="columns">
            <div className="column-left">
                <Link to="/">Back to Home</Link><br /><br />
                <text>Add to calendar </text><br />
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=X2M0b2plcDFuY2xnajZiOWpjNWltYWI5a2M0bzNlYmIyNmdxM2diOWg2NHEzMGM5aDY5Z200b2o0Y2sgZGdpZWJpc2NoQG0&tmsrc=dgiebisch%40gmail.com"
                    onClick={() => trackClick('google')}
                    className="hover:underline"
                >
                    Google
                </a>
                <text> </text>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={liveApple}
                    onClick={() => trackClick('apple')}
                    className="hover:underline"
                >
                    Apple
                </a>
                <br /><br />
            </div>
            <div className="column-right">
                <Subscribe />
            </div>
        </div>
    );
}

export function LiveShow(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    props.setColor('white');
    props.setAscii(false);
    return (<>
        <Float>
            <mesh position={[0, 0, -1]}>
                <Image url={liveBackground} scale={30}></Image>

            </mesh>
            <mesh position={[0, 0, 0]}>
                <Image url={liveGrid} scale={30} transparent={true} />
            </mesh>
            <mesh position={[0, 0, 1]}>
                <Image url={liveCello} scale={30} zoom={1} transparent={true} />
            </mesh>
            <mesh position={[3.5, -6, 2]}>
                <Image url={liveText} scale={32} transparent={true} zoom={0.5} />
            </mesh>
            <mesh position={[8, -1, 2]}>
                <Html className="content" position={[-5.5, 4.5, 1.5]} transform>
                    <TrackedCalendarLinks />
                </Html>
            </mesh>
        </Float>
        <color attach="background" args={['white']} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[10, 10, 10]} />
    </>
    )

}
