import React, { useState } from 'react';
import { getDatabase, push, ref } from "firebase/database";
import {auth } from "./auth";
auth();

const ContactForm = (props) => {
    
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [submitted, submit] = useState(false);
    const [error, setError] = useState({
        email: '',
        phone: '',
    });

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let emailError = '';
        let phoneError = '';

        if (!validateEmail(email)) {
            emailError = 'Invalid email address';
        }

        var newPhone = phone.replace(/-/g, "");

        if (phone !== '' & !validatePhone(newPhone)) {
            phoneError = 'Invalid phone number';
        }

        if (emailError || phoneError) {
            setError({ email: emailError, phone: phoneError});
            return;
        }

        const time = Date();
        const db = getDatabase();
        push(ref(db, 'contacts/'), {
            email,
            newPhone,
            time,
        });

        console.log('Contact added to the database: ', { email, newPhone, time });
        submit(true);
    };


    return (
        <form className="form" onSubmit={handleSubmit}>
            {/* <Link href="/" className='back'>
                <img src={back} className='icon back' alt='back' /><br />
            </Link> */}
            {submitted ?
                <div>
                    <div className='title'>subscribed!</div>
                    <div className="subtitle">thanks</div>
                </div> :
                <div>
                    <div className="title"><strong>Subscribe</strong></div>
                    <div className="input-container ic2">
                        <label className="placeholder">email </label>
                        <div></div>
                        <input className="input" type="text" placeholder=" " value={email} onChange={(e) => setEmail(e.target.value)} />
                        <div className="cut cut-short"></div>
                        <div className="error">{error.email}</div>
                    </div>
                    <div className="input-container ic2">
                        <label className="placeholder">phone (optional) </label>
                        <div></div>
                        <input className="input" type="text" placeholder=" " value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <div className="cut cut-long"></div>
                        <div className="error">{error.phone}</div>
                    </div>

                    <button type="submit" className='button submit'>Submit</button>
                </div>}
        </form>
    );
};

export default ContactForm;
