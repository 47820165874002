import { Text} from '@react-three/drei';

export function Logo(props) {
    return (
        <mesh
            {...props}>
           <Text fontSize={6} textAlign='center' fontWeight={500}>bluetrails</Text>
            {/* <textGeometry args={["bluetrails", textOptions]} />
            <meshStandardMaterial color="white" /> */}
        </mesh>
    )
  }