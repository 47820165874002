import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export function auth(){
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSGlBBGu9_tfUy028p90-wuRNH5zV3kh0",
    authDomain: "bluetrailsmusic.firebaseapp.com",
    databaseURL: "https://bluetrailsmusic-default-rtdb.firebaseio.com",
    projectId: "bluetrailsmusic",
    storageBucket: "bluetrailsmusic.appspot.com",
    messagingSenderId: "200268632966",
    appId: "1:200268632966:web:760fb07058907d2cae1207",
    measurementId: "G-JM7NZF9REV"
};

// Initialize Firebase
if (process.env.NODE_ENV === 'development') {
    console.log("Local Debug Token being used.")
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_DEBUG_TOKEN;
}

const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LeNPWokAAAAAPuumuqdisUUnLcZFyswlQSV7uxA'),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});
}
